/*  eslint-disable */
import React from "react"
import config from "src/config"

const BookDemo = ({}) => {
  React.useEffect(() => {
    window.location.replace(
      config.CALENDLY.ENTERPRISE
    )
  }, [])
  return null
}

export default BookDemo
